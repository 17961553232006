<template>

  
  <DownloadApp/>
</template>

<script>

import DownloadApp from './components/DownloadApp.vue'


export default {
  name: 'App',
  components: {
    
    DownloadApp
  }
}
</script>

<style>
#app {
  
 
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
